import { api } from '@/api';
import {
  OAuth2Status,
  type OAuth2Provider,
  type UserGetOAuth2Token,
  type UserUpdateData,
} from '@/api/types';
import { isStringOrError } from '@/utils';
import { showError } from '@/utils/alert';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { useBroadcastChannel } from '@vueuse/core';

export const updateUserData = (userProperties: UserUpdateData) => {
  return api.users.updatePrincipalData.mutate(userProperties);
};

export const useOAuth2ProviderToken = (provider: OAuth2Provider) => {
  const { post } = useBroadcastChannel({ name: `${provider} OAuth2` });

  return useMutation({
    mutationFn: async (
      dto: Pick<UserGetOAuth2Token, 'code' | 'redirectUrl'>,
    ) => {
      try {
        await api.users.credentials.handleOAuthCode.query({ ...dto, provider });
        const result = OAuth2Status.Authorized;
        post(result);
        return result;
      } catch (error) {
        if (isStringOrError(error)) {
          showError(error);
        }

        const result = OAuth2Status.Failed;
        post(result);
        return result;
      }
    },
  });
};

export const setPrimaryAccount = (accountId: string) => {
  return api.users.setPrimaryAccount.mutate({ accountId });
};

export const invite = (emails: string[]) => {
  return api.users.invite.mutate({ emails });
};

export const useUpdateUserData = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UserUpdateData) =>
      api.users.updatePrincipalData.mutate(data),
    onSuccess: () => queryClient.invalidateQueries(['user']),
  });
};
