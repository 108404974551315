import { ref, Ref } from 'vue';
import { SubscriptionInterval } from '@/api/types';

export interface BillingPeriodSelectorHook {
  billingPeriodSelector: Ref<SubscriptionInterval>;
}

// Display names for billing periods
export const BillingPeriodsMap: Record<SubscriptionInterval, string> = {
  [SubscriptionInterval.Month]: 'monthly',
  [SubscriptionInterval.Quarter]: 'quarterly',
  [SubscriptionInterval.Year]: 'annually',
};

// Labels for radio buttons
export const BillingPeriodLabelsMap: Record<SubscriptionInterval, string> = {
  [SubscriptionInterval.Month]: 'Monthly',
  [SubscriptionInterval.Quarter]: 'Quarterly',
  [SubscriptionInterval.Year]: 'Annually (Save 50%)',
};

const billingPeriodSelector = ref<SubscriptionInterval>(
  SubscriptionInterval.Month,
);
export const useBillingPeriodSelector = (): BillingPeriodSelectorHook => {
  return {
    billingPeriodSelector,
  };
};
