import byteSize from 'byte-size';
import { extension } from 'mime-types';

export const MAX_SIZE = 800_000; // 800 KB
export const ALLOWED_MIME_TYPES = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/avif',
  'image/webp',
  'image/apng',
];

export class MaxSizeExceededError extends Error {
  constructor() {
    const maxByteSize = byteSize(MAX_SIZE);
    super(`File size exceeds the maximum limit of ${maxByteSize.value}${maxByteSize.unit}`);
  }
}

export class InvalidMimeTypeError extends Error {
  constructor() {
    super(
      `Invalid file type. Only ${ALLOWED_MIME_TYPES.map((t) => extension(t)).join(
        ', ',
      )} are allowed`,
    );
  }
}
