import { Ref, ref, computed, ComputedRef } from 'vue';
import {
  Plan,
  PlanDifference,
  useBilling,
  BillingHook,
  useComparePlans,
} from '.';
import { useUserId } from '@/auth/use-session';
import { useCurrentWorkspace } from '@/auth/use-workspace';
import {
  AccountRole,
  Pricing,
  SubscriptionInterval,
  WorkspaceAccountPerson,
} from '@/api/types';
import { ArrayElement } from '@/utils/types';
import { MONTHS_IN_PERIOD } from '@/constants/common';
import {
  useUpdateSubscription,
  useCancelSubscription,
} from '@/api/subscriptions';
import { isStringOrError } from '@/utils';
import { showError } from '@/utils/alert';

interface WorkspaceUser {
  accountId: string;
  userId: string;
  role: AccountRole;
  name: string;
  email: string;
  avatarUrl: string;
  userPrice: string;
  isCurrentUser: boolean;
}

interface UpdatePlanHook extends Pick<BillingHook, 'billingPeriodSelector'> {
  selectedPlan: Ref<Plan | null>;
  planDifference: ComputedRef<PlanDifference>;
  setPlan: (plan: Plan) => void;
  clearPlan: () => void;
  popupTitle: ComputedRef<string>;
  workspaceUsers: ComputedRef<WorkspaceUser[]>;
  monthlyTotal: ComputedRef<string>;
  periodTotal: ComputedRef<string>;
  update: () => Promise<void>;
  isUpdating: Ref<boolean>;
}

const isUpdating = ref(false);
const selectedPlan = ref<Plan | null>(null);
const setPlan = (plan: Plan): void => {
  selectedPlan.value = plan;
};
const clearPlan = (): void => {
  selectedPlan.value = null;
};

const computePopupTitle = (diff: ComputedRef<PlanDifference>): string => {
  switch (diff.value) {
    case PlanDifference.PriceDowngrade:
      return `Downgrade to ${selectedPlan.value?.name}`;
    case PlanDifference.PriceUpgrade:
      return `Upgrade to ${selectedPlan.value?.name}`;
    case PlanDifference.PeriodDowngrade:
      return 'Downgrade billing period';
    case PlanDifference.PeriodUpgrade:
      return 'Upgrade billing period';
    default:
      return 'No changes';
  }
};

const useMapAccountToWorkspaceUser =
  (
    currentUserId: ComputedRef<string | undefined>,
    userPrice: ComputedRef<string>,
  ) =>
  ({
    userId,
    role,
    person,
  }: ArrayElement<WorkspaceAccountPerson['accounts']>): WorkspaceUser => ({
    accountId: person.id,
    userId: userId ?? '',
    name: person.name ?? '',
    email: person.email ?? '',
    avatarUrl: person.avatarUrl ?? '',
    role: <AccountRole>role,
    userPrice: role === AccountRole.LITE ? '0' : userPrice.value,
    isCurrentUser: userId === currentUserId.value,
  });

const calculateMonthlyTotal = (
  workspaceUsers: ComputedRef<WorkspaceUser[]>,
): string => {
  const totalNumber = workspaceUsers.value.reduce(
    (total, { userPrice }) => +userPrice + total,
    0,
  );
  return totalNumber ? totalNumber.toFixed(2) : '0';
};

const calculatePeriodTotal = (
  monthlyTotal: ComputedRef<string>,
  period: Ref<SubscriptionInterval>,
): string => {
  const months = MONTHS_IN_PERIOD[period.value];
  const total = +monthlyTotal.value * months;
  return total ? total.toFixed(2) : '0';
};

const useUpdate =
  (updateMutation: ComputedRef<() => Promise<void>>) =>
  async (): Promise<void> => {
    try {
      isUpdating.value = true;
      await updateMutation.value();
      clearPlan();
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    } finally {
      isUpdating.value = false;
    }
  };

export const useUpdatePlan = (): UpdatePlanHook => {
  const { currentPlan, billingPeriodSelector } = useBilling();

  const { planDifference } = useComparePlans(
    // Fallback to current plan in order to avoid error while closing the popup
    () => selectedPlan.value ?? currentPlan.value ?? ({} as Plan),
  );

  const popupTitle = computed(() => computePopupTitle(planDifference));

  const currentUserId = useUserId();
  const userPrice = computed(
    () => selectedPlan.value?.prices[billingPeriodSelector.value].price ?? '0',
  );
  const mapAccountToWorkspaceUser = useMapAccountToWorkspaceUser(
    currentUserId,
    userPrice,
  );

  const { data: workspace } = useCurrentWorkspace();
  const workspaceUsers = computed(() =>
    (workspace.value?.accounts ?? []).map(mapAccountToWorkspaceUser),
  );

  const monthlyTotal = computed(() => calculateMonthlyTotal(workspaceUsers));
  const periodTotal = computed(() =>
    calculatePeriodTotal(monthlyTotal, billingPeriodSelector),
  );

  const { mutateAsync: updateSubscriptionMutation } = useUpdateSubscription();
  const { mutateAsync: cancelSubscriptionMutation } = useCancelSubscription();
  const updateMutation = computed(() =>
    selectedPlan.value?.pricing === Pricing.PERSONAL
      ? cancelSubscriptionMutation
      : updateSubscriptionMutation.bind(
          null,
          selectedPlan.value?.prices[billingPeriodSelector.value].id ?? '',
        ),
  );
  const update = useUpdate(updateMutation);

  return {
    selectedPlan,
    billingPeriodSelector,
    planDifference,
    setPlan,
    clearPlan,
    popupTitle,
    workspaceUsers,
    monthlyTotal,
    periodTotal,
    update,
    isUpdating,
  };
};
