import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { api } from '@/api';
import { useCurrentWorkspaceId } from '@/auth/use-workspace';
import { showError } from '@/utils/alert';
import { delay } from '@/utils';
import { TRPCClientError } from '@trpc/client';

export const updateSubscription = async (
  workspaceId: string,
  priceId: string,
  promotionCodeId?: string,
  trialPeriodDays?: number,
) => {
  const res = await api.subscriptions.updateSubscription.mutate({
    workspaceId,
    priceId,
    promotionCodeId,
    trialPeriodDays,
  });
  if (res?.checkoutRedirectUrl) {
    window.open(res.checkoutRedirectUrl, '_self');
  }
};

export const cancelSubscription = async (workspaceId: string) => {
  return await api.subscriptions.cancelSubscription.mutate({ workspaceId });
};

export const getBillingPortalLink = (workspaceId?: string) => {
  return api.subscriptions.billingPortalLink.mutate({ workspaceId });
};

export const applyPromotionCode = async (
  workspaceId: string,
  promotionCode: string,
) => {
  try {
    return await api.subscriptions.applyPromotionCode.mutate({
      workspaceId,
      promotionCode,
    });
  } catch (e) {
    showError(e as Error);
    throw e;
  }
};

export const getWorkspaceSubscription = (workspaceId: string) => {
  return api.subscriptions.getWorkspaceSubscription.query({ workspaceId });
};

export const useWorkspaceSubscriptionDetails = () => {
  const workspaceId = useCurrentWorkspaceId();

  return useQuery({
    enabled: !!workspaceId.value,
    queryKey: ['workspaceSubscriptionDetails', workspaceId],
    queryFn: () =>
      api.subscriptions.getWorkspaceSubscription
        .query({
          workspaceId: workspaceId.value,
        })
        .catch((error) => {
          if (
            error instanceof TRPCClientError &&
            error.data.code === 'NOT_FOUND'
          ) {
            console.warn('Subscription not found');
            return null;
          }
          throw error;
        }),
  });
};

export const useGetProducts = () => {
  return useQuery({
    queryKey: ['products'],
    queryFn: () => api.subscriptions.getProducts.query({ all: false }),
  });
};

export const useBillingPortal = () => {
  const workspaceId = useCurrentWorkspaceId();

  return useMutation({
    mutationFn: () => {
      if (!workspaceId.value) {
        throw new Error('Workspace id is required');
      }

      return api.subscriptions.billingPortalLink.mutate({
        workspaceId: workspaceId.value,
      });
    },
  });
};

export const useCancelSubscription = () => {
  const queryClient = useQueryClient();
  const workspaceId = useCurrentWorkspaceId();
  return useMutation({
    mutationFn: async () => {
      if (!workspaceId.value) {
        throw new Error('Workspace id is required');
      }

      await api.subscriptions.cancelSubscription.mutate({
        workspaceId: workspaceId.value,
      });
      /* the time it takes by Stripe to send the webhook */
      await delay(5000);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['workspaceSubscriptionDetails']);
    },
  });
};

export const useUpdateSubscription = () => {
  const queryClient = useQueryClient();
  const workspaceId = useCurrentWorkspaceId();
  return useMutation({
    mutationFn: async (priceId: string) => {
      if (!workspaceId.value) {
        throw new Error('Workspace id is required');
      }

      const res = await api.subscriptions.updateSubscription.mutate({
        workspaceId: workspaceId.value,
        priceId,
      });

      res?.checkoutRedirectUrl && window.open(res.checkoutRedirectUrl, '_self');
    },
    onSettled: () => {
      queryClient.invalidateQueries(['workspaceSubscriptionDetails']);
    },
  });
};
