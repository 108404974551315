<script setup lang="ts">
import { SubscriptionInterval } from '@/api/types';
import { useBillingPeriodSelector, BillingPeriodLabelsMap } from './hooks';

const { billingPeriodSelector } = useBillingPeriodSelector();
</script>
<template>
  <div class="flex items-center p-[2px] rounded bg-grey-10 w-fit">
    <label
      v-for="interval in SubscriptionInterval"
      :key="interval"
      class="relative py-[6px] pl-3 pr-4 cursor-pointer rounded"
      :class="{
        'bg-primary': billingPeriodSelector === interval,
      }"
    >
      <input
        v-model="billingPeriodSelector"
        name="billing-period"
        :value="interval"
        type="radio"
        class="invisible z-0 absolute"
      />
      <span class="typo-body1 font-medium">
        {{ BillingPeriodLabelsMap[interval] }}
      </span>
    </label>
  </div>
</template>
