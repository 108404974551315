import { computed, ComputedRef, Ref } from 'vue';
import { Pricing, SubscriptionInterval } from '@/api/types';
import { Plan, PlanWithSubscription, useBilling } from '.';

export enum PlanDifference {
  Equal,
  PriceDowngrade,
  PriceUpgrade,
  PeriodDowngrade,
  PeriodUpgrade,
}

const PlanSequence = [
  Pricing.PERSONAL,
  Pricing.PERSONAL_PRO,
  Pricing.TEAM,
  Pricing.ENTERPRISE,
];
const PeriodSequence = [
  SubscriptionInterval.Month,
  SubscriptionInterval.Quarter,
  SubscriptionInterval.Year,
];

interface ComparePlansHook {
  planDifference: ComputedRef<PlanDifference>;
  showDiscount: ComputedRef<boolean>;
  discountPercent: ComputedRef<number>;
}

const compare = (
  currentPlan: Ref<PlanWithSubscription | undefined>,
  billingPeriodSelector: Ref<SubscriptionInterval>,
  plan: Plan,
): PlanDifference => {
  if (!currentPlan.value) {
    return PlanDifference.Equal;
  }

  if (
    currentPlan.value.subscription.id ===
      plan.prices[billingPeriodSelector.value].id ||
    // Free subscription has no interval difference
    (currentPlan.value.pricing === Pricing.PERSONAL &&
      Pricing.PERSONAL === plan.pricing)
  ) {
    return PlanDifference.Equal;
  }

  if (currentPlan.value.pricing === plan.pricing) {
    return PeriodSequence.indexOf(currentPlan.value.subscription.interval) <
      PeriodSequence.indexOf(billingPeriodSelector.value)
      ? PlanDifference.PeriodUpgrade
      : PlanDifference.PeriodDowngrade;
  }

  return PlanSequence.indexOf(currentPlan.value.pricing) <
    PlanSequence.indexOf(plan.pricing)
    ? PlanDifference.PriceUpgrade
    : PlanDifference.PriceDowngrade;
};

export const useComparePlans = (planGetter: () => Plan): ComparePlansHook => {
  const { currentPlan, billingPeriodSelector } = useBilling();

  const planDifference = computed<PlanDifference>(() =>
    compare(currentPlan, billingPeriodSelector, planGetter()),
  );

  const showDiscount = computed(
    () =>
      Boolean(+planGetter().prices[billingPeriodSelector.value].price) &&
      billingPeriodSelector.value !== SubscriptionInterval.Month,
  );
  const discountPercent = computed(
    () =>
      +(
        100 -
        (+planGetter().prices[billingPeriodSelector.value].price /
          +planGetter().prices[SubscriptionInterval.Month].price) *
          100
      ).toFixed(0),
  );

  return {
    planDifference,
    showDiscount,
    discountPercent,
  };
};
