import { Ref, ref, ComputedRef, computed, watch } from 'vue';
import {
  useGetProducts,
  useWorkspaceSubscriptionDetails,
} from '@/api/subscriptions';
import { Product, Pricing, SubscriptionInterval } from '@/api/types';
import { MONTHS_IN_PERIOD } from '@/constants/common';
import {
  BillingPeriodsMap,
  BillingPeriodSelectorHook,
  useBillingPeriodSelector,
} from '.';

export interface PlanBenefit {
  quota: string;
  description: string;
  period?: string;
}

interface PlanPrice {
  id: string;
  price: string;
  interval: SubscriptionInterval;
  billingPeriod: string;
}

export interface Plan {
  id: string;
  name: string;
  description: string;
  pricing: Pricing;
  benefits: PlanBenefit[];
  prices: Record<SubscriptionInterval, PlanPrice>;
}

export interface PlanWithSubscription extends Plan {
  subscription: PlanPrice;
}

export interface BillingHook extends BillingPeriodSelectorHook {
  currentPlan: Ref<PlanWithSubscription | undefined>;
  plans: ComputedRef<Plan[] | undefined>;
  isLoading: ComputedRef<boolean>;
}

// TODO: Remove after removing unavailable plans
const pricingReducer = (plan?: Pricing): Pricing | undefined => {
  switch (plan) {
    case Pricing.PERSONAL:
      return Pricing.PERSONAL;
    case Pricing.PERSONAL_PRO:
    case Pricing.TEAM:
    case Pricing.ENTERPRISE:
      return Pricing.PERSONAL_PRO;
  }
};

const intervalPriceMatcher = (
  price: Pick<Product['prices'][number], 'interval' | 'intervalUnit'>,
  interval: SubscriptionInterval,
): boolean => {
  switch (interval) {
    case SubscriptionInterval.Year:
      return (
        price.interval === 1 && price.intervalUnit === SubscriptionInterval.Year
      );
    case SubscriptionInterval.Quarter:
      return (
        price.interval === 3 &&
        price.intervalUnit === SubscriptionInterval.Month
      );
    case SubscriptionInterval.Month:
      return (
        price.interval === 1 &&
        price.intervalUnit === SubscriptionInterval.Month
      );
    default:
      return false;
  }
};

const mapPrices = (prices: Product['prices']): Plan['prices'] => {
  return Object.values(SubscriptionInterval).reduce(
    (acc, interval) => {
      const price = prices.find((price) =>
        intervalPriceMatcher(price, interval),
      );
      return {
        ...acc,
        [interval]: {
          id: price?.id ?? '',
          price: price?.unitAmount
            ? (price.unitAmount / MONTHS_IN_PERIOD[interval] / 100).toFixed(2)
            : '0',
          interval,
          billingPeriod: BillingPeriodsMap[interval],
        },
      };
    },
    {} as Plan['prices'],
  );
};

const mapProductToPlan = (product: Product): Plan => {
  const { id, plan, features, description, prices, name = '' } = product;

  let benefits: PlanBenefit[] = [];
  try {
    benefits = JSON.parse(features?.benefits ?? '');
  } catch (error) {
    console.error(`Benefits for plan ${name} are not properly defined`);
  }

  return {
    id,
    name,
    description: description ?? '',
    pricing: plan,
    benefits,
    prices: mapPrices(prices),
  };
};

const currentPlan = ref<PlanWithSubscription | undefined>();

export const useBilling = (): BillingHook => {
  const { billingPeriodSelector } = useBillingPeriodSelector();
  const { data: products, isLoading: areProductsLoading } = useGetProducts();
  const {
    data: subscription,
    isLoading: isSubscriptionLoading,
    isRefetching: isSubscriptionRefetching,
  } = useWorkspaceSubscriptionDetails();

  const plans = computed(() => products.value?.map(mapProductToPlan) ?? []);
  watch([plans, subscription], () => {
    let fallback: PlanWithSubscription | undefined;
    const pricing = pricingReducer(subscription.value?.plan);

    for (const plan of plans.value) {
      if (plan.pricing === pricing) {
        currentPlan.value = {
          ...plan,
          subscription:
            Object.values(plan.prices).find(
              (price) => price.id === subscription.value?.priceId,
            ) ?? plan.prices[SubscriptionInterval.Month],
        };

        // Open the billing period selector with the current subscription interval by default
        billingPeriodSelector.value = currentPlan.value.subscription.interval;
        return;
      } else if (!fallback && plan.pricing === Pricing.PERSONAL) {
        fallback = {
          ...plan,
          subscription: plan.prices[SubscriptionInterval.Month],
        };
      }
    }
    currentPlan.value = fallback;
  });

  const isLoading = computed(
    () =>
      areProductsLoading.value ||
      isSubscriptionLoading.value ||
      isSubscriptionRefetching.value,
  );

  return {
    billingPeriodSelector,
    currentPlan,
    plans,
    isLoading,
  };
};
