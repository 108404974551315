import { supabase } from '@/auth/supabase';
import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocationRaw,
} from 'vue-router';
import { updateExtensionConfig } from '@/extension/config';
import { isOnboardingCondition } from '@/utils/onboarding';
import { trackEvent, trackEventFromUtms, trackView } from '@/utils/analytics';
import { setReferralEmail } from '@/utils/analytics/referral_email';
import { ROUTE_NAMES } from '@/constants/routeNames';

const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: '/workspace',
    children: [
      {
        path: '',
        alias: '/',
        name: ROUTE_NAMES.HOME,
        beforeEnter(to, from) {
          if (from.name === ROUTE_NAMES.DASHBOARD) {
            return false;
          }
        },
        component: () => import('@/pages/Home.vue'),
      },
      {
        path: ':workspaceId',
        name: ROUTE_NAMES.DASHBOARD,
        component: () => import('@/pages/DashboardPage.vue'),
      },
      {
        name: ROUTE_NAMES.CONVERSATIONS,
        path: ':workspaceId/conversations',
        component: () => import('@/pages/Conversations.vue'),
      },
    ],
  },
  {
    name: ROUTE_NAMES.SETTINGS_NEW.INDEX,
    path: '/settings',
    component: () => import('@/pages/Settings.vue'),
    redirect: { name: ROUTE_NAMES.SETTINGS_NEW.PERSONAL },
    children: [
      {
        path: 'personal',
        name: ROUTE_NAMES.SETTINGS_NEW.PERSONAL,
        component: () => import('@/pages/PersonalSettings.vue'),
      },
      {
        path: 'workspace',
        name: ROUTE_NAMES.SETTINGS_NEW.WORKSPACE.INDEX,
        component: () =>
          import('@/pages/workspace-settings/WorkspaceSettingsContainer.vue'),
        children: [
          {
            path: ':workspaceId',
            name: ROUTE_NAMES.SETTINGS_NEW.WORKSPACE.WORKSPACE,
            component: () =>
              import('@/pages/workspace-settings/WorkspaceSettings.vue'),
          },
          {
            path: ':workspaceId/folders',
            name: ROUTE_NAMES.SETTINGS_NEW.WORKSPACE.FOLDERS,
            component: () =>
              import('@/pages/workspace-settings/WorkspaceSettingsFolders.vue'),
          },
          {
            path: ':workspaceId/team',
            name: ROUTE_NAMES.SETTINGS_NEW.WORKSPACE.TEAM,
            component: () =>
              import('@/components/settings/Members/Members.vue'),
          },
          {
            path: ':workspaceId/billings',
            name: ROUTE_NAMES.SETTINGS_NEW.WORKSPACE.BILLINGS,
            component: () => import('@/pages/workspace-settings/Billing.vue'),
          },
          {
            path: ':workspaceId/integrations',
            name: ROUTE_NAMES.SETTINGS_NEW.WORKSPACE.INTEGRATIONS,
            component: () => import('@/components/settings/Integrations'),
          },
        ],
      },
    ],
  },
  // Outstanding route in order not to show the settings navigation
  {
    path: '/settings/workspace/:workspaceId/billings/portal',
    name: ROUTE_NAMES.SETTINGS_NEW.WORKSPACE.BILLING_PORTAL,
    component: () => import('@/pages/workspace-settings/BillingPortal.vue'),
    meta: { noLayout: true },
  },
  // TODO: remove later
  {
    name: ROUTE_NAMES.SETTINGS.FOLDERS,
    path: '/workspace/:workspaceId/folders',
    component: () => import('@/components/settings/Spaces'),
    children: [
      {
        name: ROUTE_NAMES.SETTINGS.FOLDER,
        path: '/workspace/:workspaceId/folders/:spaceId',
        component: () =>
          import('@/components/settings/Spaces/SpaceDetails.vue'),
      },
    ],
  },
  {
    name: ROUTE_NAMES.SIGN_IN,
    path: '/sign-in',
    component: () => import('@/pages/SignIn.vue'),
    meta: { requireGuest: true, noLayout: true },
  },
  {
    name: ROUTE_NAMES.SIGN_IN_OAUTH,
    path: '/sign-in-oauth',
    component: () => import('@/pages/SignIn.vue'),
    meta: { allowGuest: true, noLayout: true },
  },
  {
    path: '/oauth2',
    meta: { noLayout: true },
    children: [
      {
        name: ROUTE_NAMES.OAUTH2.AUTHORIZE,
        path: 'authorize',
        component: () => import('@/pages/OAuth2.vue'),
      },
      {
        name: ROUTE_NAMES.OAUTH2.PROVIDER_TOKEN,
        path: ':provider/token',
        component: () => import('@/pages/OAuth2ProviderToken.vue'),
      },
    ],
  },
  {
    name: ROUTE_NAMES.LOADING,
    path: '/loading',
    component: () => import('@/pages/Loading.vue'),
    meta: { allowGuest: true },
  },
  {
    name: ROUTE_NAMES.CONVERSATION,
    path: '/conversation/:id',
    component: () => import('@/pages/ConversationNew.vue'),
    meta: { allowGuest: true },
  },
  {
    name: ROUTE_NAMES.CREATE_WORKSPACE,
    path: '/create-workspace',
    component: () => import('@/pages/create_workspace/CreateWorkspace.vue'),
    meta: { noLayout: true },
  },
  {
    name: ROUTE_NAMES.ONBOARDING,
    path: '/onboarding',
    component: () => import('@/pages/Onboarding.vue'),
    meta: { noLayout: true },
  },
  {
    name: ROUTE_NAMES.EXTENSION_UNINSTALLED,
    path: '/extension-uninstalled-survey',
    component: () => import('@/pages/ExtensionUninstalled.vue'),
    meta: { allowGuest: true, noLayout: true },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: { name: ROUTE_NAMES.HOME },
  },
];

export const router = createRouter({
  routes,
  history: createWebHistory(),
});

/* Clean up the GA query parameter */
router.beforeEach((to) => {
  if (to.query._gl) {
    return { ...to, query: { ...to.query, _gl: undefined } };
  }
});

/* by default routes do not allow guest */
router.beforeEach(async (to) => {
  if (
    to.query.source === 'extension' &&
    to.query.extensionInstalled === 'true'
  ) {
    updateExtensionConfig({ gmeetEnabled: true });
    trackEvent('Extension set up', {});
  }

  if (to.query.promotion_code) {
    /* apply promotion code if exist */
    localStorage.setItem('DiscountNotification', Date.now().toString());
    localStorage.setItem('PromotionCode', to.query.promotion_code.toString());
  }

  if (to.query.referral_email) {
    /* save referral_email for the further processing within 'Onboarding setup finish' event */
    setReferralEmail(to.query.referral_email.toString());
  }

  if (to.name !== ROUTE_NAMES.ONBOARDING) {
    if (isOnboardingCondition()) {
      return { name: ROUTE_NAMES.ONBOARDING };
    }
  }

  const allowGuest = to.meta.allowGuest || to.meta.requireGuest;
  const user = await supabase.auth
    .getSession()
    .then(({ data }) => data.session?.user);

  if (to.meta.requireGuest && user) {
    trackEventFromUtms(to.query);
    let dest: RouteLocationRaw = { name: ROUTE_NAMES.HOME };
    const redirect = to.query.redirect?.toString();
    if (redirect) {
      const url = new URL(redirect, window.location.origin);
      dest = {
        path: url.pathname,
        query: Object.fromEntries(url.searchParams),
      };
    }

    return dest;
  }
  if (!allowGuest && !user) {
    return {
      name: ROUTE_NAMES.SIGN_IN,
      query: { ...to.query, redirect: to.fullPath },
    };
  }
});

router.afterEach((to) => trackView(to.path, to.name?.toString()));
